import { useEffect } from "react";
import imgControl from "../../assets/img/controller-Icon.png";
import { Row } from "../Row/Row";
type prop = {
  titular: string;
  cotizacion: number;
};
export const Titular = ({ titular, cotizacion }: prop) => {
  useEffect(() => {
    document.title = `Banca Gamer - ${titular}`;
  });
  return (
    <div className="game-title-sticky">
      <Row>
        <img src={imgControl} alt="" className="icon-control" />
        <div className="header-videojuego">
          <b>Videojuego</b>
          <h2>{titular}</h2>
        </div>
      </Row>
      <div className="value-container">
        <div className="value-estimate">
          <span className="valor-estimado">${cotizacion.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};
