import { db } from "./config";
import { setDoc, doc, getDocs, collection } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth();
const fechaLocal = new Date().toLocaleString();
export const sendEmail = async (
  correo: string,
  nombre: string,
  cedula: string,
  nickname: string,
  juego: string,
  valor: number,
  submit: Function
) => {

  const mensaje = {
    subject: "Revisión de crédito",
    html: `<img src="https://astrid-lab.com/public/cabecera002.png"><p>Estimado,</p>${nombre}</p><br/><p>Hemos recibido correctamente tu información para tu próximo crédito. Estos son los detalles:</p><ul><li><b>Nombre:</b> ${nombre}</li><li><b>Juego:</b> ${juego}</li><li><b>Usuario:</b> ${nickname}</li><li><b>Cédula:</b> ${cedula}</li><li><b>Correo:</b> ${correo}</li><li><b>Valor del Juego:</b> $${valor}</li></ul><p>Revisaremos tus datos internamente y en caso de ser aprobado nos estaremos comunicando contigo directamente. En caso contrario, vuelve a intentar en unas semanas.</p><p><b>Fecha y Hora: </b> ${fechaLocal}</p><p>Gracias por usar la Banca Gamer</p><p>Banco Del Pacífico</p><a href="https://bancagamer.com">bancagamer.com</a>`,
  };

  onAuthStateChanged(auth, async (user) => {
    console.log("verificando sesion anonima")
    if (user) {
      console.log("sesion anonima correcta")
      const uid = user.uid;
      const usuarioRegistrado = user.metadata.creationTime;
      const ultimoIngreso = user.metadata.lastSignInTime;
      const esAnonimo = user.isAnonymous
      await writeMail(correo, mensaje, uid);
      await writeUser(
        {
          nombre: nombre,
          correo: correo,
          nickname: nickname,
          cedula: cedula,
          juego: juego,
          estadoCredito: "pendiente",
          id: uid,
          registro: usuarioRegistrado,
          ulTimoingreso: ultimoIngreso,
          esAnonimo: esAnonimo,
        },
        uid, submit
      );
    } else {
      console.log("No has hecho un inicio de sesion anonimo");
    }
  });
};


/// #Debo hacer la consulta en primer lugar para ver si escribo y envio el correo
async function writeUser(userData: {}, docId: string, submit: Function) {
  await setDoc(doc(db, "users", docId), userData)
    .then(() => {
      console.log("Usuario guardado");
      submit();
    })
    .catch((error) =>
      console.error(
        "Error adding document: ",
        error + "Mostrar una pantalla de error"
      )
    );
}

async function writeMail(correo: string, mensaje: {}, docId: string) {
  setDoc(doc(db, "mail", docId), {
    to: correo,
    message: mensaje,
  }).catch((error) => console.error("Error adding document: ", error));
}


export function writeGames(game: {
  name: string; gameid: string;
  image: string;
  value: number[];
  destino: string;
}) {
  setDoc(doc(db, "games", game.gameid), game)
    .then(() => { console.log("Juego agregado: " + game.name) })
    .catch((error) => console.error("Error adding game: ", error));
}
export async function readGames() {
  // console.log("Llamando a la collecion games")
  const games: {
    gameid: string;
    name: string;
    image: string;
    value: number[];
    destino: string;
  }[] = []
  const querySnapshot = await getDocs(collection(db, "games"));
  // console.log(querySnapshot.docs.length) //is never undefined for query doc snapshots
  querySnapshot.forEach((doc) => {
    // console.log(doc.id, " => ", doc.data());
    games.push({
      gameid: doc.get("gameid"),
      name: doc.get("name"),
      image: doc.get("image"),
      value: doc.get("value"),
      destino: doc.get("destino"),
    });
  });
  return games;
}
