import React, { useEffect, useState } from "react";
import "./App.css";
import "./components/GameGrid/GameOptions";
import AppRouter from "./Router/AppRouter";
import { anonimousUser } from "./utils/UserSession";
import { Loader } from "./components/Loader/Loader";
import { GameItemProvider } from "./Context/Context";



function App() {
  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {
    anonimousUser();
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 10000);
    return () => {
      clearTimeout(timeoutId); // Limpiar el timeout en caso de que el componente sea desmontado antes de que se ejecute
    };
  }, []);
  return (

      <GameItemProvider>
        <AppRouter />
      <Loader isLoading={isLoading} /> 
      <div id="videoCalculadoras" style={{display:"none"}}>
        <div className="calculadora-video" style={{ zIndex: "-4" }}>
          <video controls={false} autoPlay={true} loop muted playsInline={false}>
            <source
              src="https://astrid-lab.com/public/fondo5.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
      </div>
      <div id="videoFaqs" style={{display:"none"}}>
        <div className="homebg" style={{ zIndex: "-3" }}>
          <video controls={false} autoPlay={true} loop muted playsInline={false}>
            <source
              src="https://astrid-lab.com/public/fondo4.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
      </div>
      <div id="videoContainer" style={{display:"none"}}>
        <div className="homebg main-video">
          <video controls={false} autoPlay={true} loop muted playsInline={false}>
            <source
              src="https://astrid-lab.com/public/homebg.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="homebg" style={{ top: "100vh" }}>
          <video controls={false} autoPlay={true} loop muted playsInline={false}>
            <source
              src="https://astrid-lab.com/public/Fondo2.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="homebg" style={{ top: "200vh" }}>
          <video controls={false} autoPlay={true} loop muted playsInline={false}>
            <source
              src="https://astrid-lab.com/public/Fondo3.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="homebg" style={{ top: "400vh" }}>
          <video controls={false} autoPlay={true} loop muted playsInline={false}>
            <source
              src="https://astrid-lab.com/public/fondo4.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
      </div>
      </GameItemProvider>

  );
}

export default App;
