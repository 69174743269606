import { NavBar } from "../../components/NavBar/NavBar";
import { useEffect, useState } from "react";
import InputText from "../../components/InputText/InputText";
import { Titular } from "../../components/Titular/Titular";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { Row } from "../../components/Row/Row";
import { Dialog } from "../../components/Dialog/Dialog";
import { minecraftValores } from "../../data/ValorDeItems";
import { useLocation } from "react-router-dom";
export const Minecraft = () => {
  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);

  const [accountAge, setAccountAge] = useState(0);
  const [originalName, setOriginalName] = useState(false);
  const [mvp, setMvp] = useState(false);
  const [hypixel, setHypixel] = useState(false);
  const mvpValor = mvp ? minecraftValores.mvp : 0;
  const originalNameValor = originalName ? minecraftValores.originalName : 0;
  const hypixelValor = hypixel ? minecraftValores.hypixel : 0;
  const nombreJuego = "Minecraft";

  let valorDeCuenta =
    accountAge * minecraftValores.accountAge +
    mvpValor +
    originalNameValor +
    hypixelValor;

  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
        ? substracAnimation(val)
        : console.log("no cambiar");

    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };

  const location = useLocation();
  const [showScrollDown, setShowScrollDown] = useState(true);
  useEffect(() => {
    const onScroll = () => {
      const percentageScroll =
        ((window.scrollY / window.innerHeight) * 100) / 4;

      if (percentageScroll <= 75) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }

    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
        window.removeEventListener("scroll", onScroll);
      }
    };
  });

  return (
    <>
      <section className="calculadora">
        <NavBar />
        <div className="form-container calculadora-padding">
          <div style={{ height: "100px" }}></div>
          <Titular cotizacion={cotizacion} titular={nombreJuego} />
          <span style={{ height: "60px" }}></span>
          <div className="grid">
            <InputText
              etiqueta={"Antigüedad de la cuenta, Años"}
              valor={accountAge}
              setValue={setAccountAge}
              aumento={1}
            />
            <div className="input-card">
              <label>Nombre Original (Sin letras)</label>
              <div className="options-container">
                <div className="outlined-radio">
                  <input
                    type="radio"
                    id="originalname"
                    name="originalname"
                    value="originalname"
                    checked={originalName}
                    onChange={() => {
                      setOriginalName(true);
                    }}
                  />
                  <label htmlFor="male">SI</label>
                </div>
                <div className="outlined-radio">
                  <input
                    type="radio"
                    id="nooriginalname"
                    name="nooriginalname"
                    value="nooriginalname"
                    checked={!originalName}
                    onChange={() => {
                      setOriginalName(false);
                    }}
                  />
                  <label htmlFor="nooriginalname">no</label>
                </div>
              </div>
            </div>

            <div className="input-card">
              <label>MVP+</label>
              <div className="options-container">
                <div className="outlined-radio">
                  <input
                    type="radio"
                    id="mvp"
                    name="mvp"
                    value="mvp"
                    checked={mvp}
                    onChange={() => {
                      setMvp(true);
                    }}
                  />
                  <label htmlFor="male">SI</label>
                </div>
                <div className="outlined-radio">
                  <input
                    type="radio"
                    id="nomvp"
                    name="nomvp"
                    value="nomvp"
                    checked={!mvp}
                    onChange={() => {
                      setMvp(false);
                    }}
                  />
                  <label htmlFor="nomvp">no</label>
                </div>
              </div>
            </div>
            <div className="input-card">
              <label>Hypixel</label>
              <div className="options-container">
                <div className="outlined-radio">
                  <input
                    type="radio"
                    id="hypixel"
                    name="hypixel"
                    value="hypixel"
                    checked={hypixel}
                    onChange={() => {
                      setHypixel(true);
                    }}
                  />
                  <label htmlFor="male">SI</label>
                </div>
                <div className="outlined-radio">
                  <input
                    type="radio"
                    id="nohypixel"
                    name="nohypixel"
                    value="nohypixel"
                    checked={!hypixel}
                    onChange={() => {
                      setHypixel(false);
                    }}
                  />
                  <label htmlFor="hypixel">no</label>
                </div>
              </div>
            </div>
          </div>
          <div className="calculadora-buttons">
            <ButtonBancaGamer
              ruta=""
              action={handleClick}
              label="CALCULAR VALOR"
              tipo={2}
            />
            <ButtonBancaGamer
              ruta=""
              action={enviarCuenta}
              label="ENVIAR"
              tipo={tipoBoton}
            />
          </div>
          <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
        </div>
        <Dialog
          openDialog={openDialog}
          closeDialog={setOpendialog}
          juego={nombreJuego}
          valor={cotizacion}
        />
      </section>
    </>
  );
};
