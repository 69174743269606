import { useEffect } from "react";
import { NavBar } from "../../components/NavBar/NavBar";
import "./Registrado.css";
import { Navigate, redirect, useNavigate } from "react-router-dom";


export const Registrado = () => {
const navigate = useNavigate()  
  useEffect(() => {
    const redirigir = setTimeout(() => {
      navigate("/");
    }, 5000);

    return ()=> clearTimeout(redirigir);
  }, []);
  return (
    <>
      <NavBar />
      <section>
        <div className="registrado-section-container">
          <strong>¡Gracias por registrarte!</strong>
          <p>
            Pronto te enviaremos más información sobre nuestros servicios para
            Gamers
          </p>
        </div>
      </section>
    </>
  );
};
