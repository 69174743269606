import React, { useRef } from "react";
import "./InputText.css";
type inputs = {
  etiqueta: string;
  valor: number;
  aumento: number;
  setValue: Function;
};
const InputText = ({ etiqueta, valor, aumento, setValue }: inputs) => {
  const inputReference = useRef<HTMLInputElement>(null);
  let val: number = valor;

  const addValue = (e: any) => {
    val = val + aumento;
    setValue(val);
    // auto(); //opcional
  };

  const decreaseValue = (e: any) => {
    val > 0 ? (val = val - aumento) : (val = 0);
    setValue(val);
    // auto(); //opcional
  };

  const handleChange = (e: any) => {
    const inputValue = e.target.value;

    // Validar que el valor sea numérico antes de actualizar el estado
    if (!isNaN(Number(inputValue))) {
      setValue(Number(inputValue));
    }
  };
  const handleClick = () => {
    // Enfocar el input cuando se hace clic en el botón
    if (inputReference.current) {
      console.log("Focus");
      inputReference.current.focus();
    }
  };

  return (
    <div className="input-card">
      <label># {etiqueta}</label>
      <div className="input-row">
        <input ref={inputReference}
          className="input-items"
          type="number "
          value={val}
          onChange={handleChange}
          maxLength={4}
        ></input>
          <button className="iconButton mas" onClick={addValue}>
            +
          </button>
          <button className="iconButton menos" onClick={decreaseValue}>
            -
          </button>
        {/* <div className="row-buttons" onClick={handleClick}>
        </div> */}
      </div>
    </div>
  );
};
export default InputText;
