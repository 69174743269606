import { createContext, useEffect, useState } from "react";
import { GameItem, Games } from "../data/Games";
import { readGames } from "../utils/SendMail";
export const GameItemListContext = createContext<GameItem[]>([]);

interface GameItemProviderProps {
  children: React.ReactNode; // children debe ser de tipo ReactNode
}

export const GameItemProvider: React.FC<GameItemProviderProps> = ({ children }) => {
  const [gameItemList, setGameItemList] = useState<GameItem[]>(Games);
  useEffect(() => {
    const fetchGames = async () => {
      const fetchedGames = await readGames();
      // const fetchedGames = Games;
      if (fetchedGames.length > 0) {
        setGameItemList(fetchedGames);
      }
      try {
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };
    fetchGames();
  }, []);

  return (
    <GameItemListContext.Provider value={gameItemList}>
      {children}
    </GameItemListContext.Provider>
  );
};