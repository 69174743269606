import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GameItemListContext } from "../../Context/Context";
import "./GamesSettings.css"
import { NavBar } from "../../components/NavBar/NavBar";
import { GameInput } from "./GameInput";

export const GameSettings = () => {
  const gameItemList = useContext(GameItemListContext);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();
  const [read, setRead] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      console.log("verificando sesion");
      if (!user?.isAnonymous) {
        setRead(true);
      } else {
        if(user === null){
          navigate("/Paradais");
        }
      }
      const videoContainer = document.getElementById("videoContainer");
      if (location.pathname === "/GamesSettings") {
        console.log(videoContainer);
        videoContainer!.style.display = "none";
      }
      return () => {
        if (videoContainer !== null) {
          videoContainer.style.display = "block";
        }
      };
    });
  });
  return !read ? null : (
    <>
      <NavBar />
      <div className="gsSection">
        <h1>Promedios de juegos</h1>
        <div className="grid">
          {gameItemList.map((game, index) => {
            // console.log(game);
            // const [juego, setJuego] = useState(0);
            return (
              <GameInput juego={game}key={index}/>
            );
          })}
        </div>
        
        <div>Juego registrado con exito</div>
      </div>
    </>
  );
};
