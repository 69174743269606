import { GameOptions } from "../../components/GameGrid/GameOptions";
import styles from "./HomePage.module.css";
import imageSection from "../../assets/img/amarillar_22_year_old_cool_gamer_with_blue_hair_headphones_wi_58bb27c9-7619-4fdc-baa9-ef5184471b3c_1.jpg";
import imageLogo from "../../assets/img/logo-banca-gamer.png";
import { ScrollToAnchor } from "../../components/NavBar/Navegacion";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { anonimousUser } from "../../utils/UserSession";
import message_es from "../../translations/en.json";
import message_en from "../../translations/en.json";
import { IntlProvider, FormattedMessage } from "react-intl";
import { NavBarTest } from "../../components/NavBar/NavBarTest";
import { NavBar } from "../../components/NavBar/NavBar";

const message = {
  es: message_es,
  en: message_en,
};

const HomePage = () => {
  const [locale, setLocale] = useState("es");

  const [showScrollDown, setShowScrollDown] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const onScroll = () => {
      const percentageScroll =
        ((window.scrollY / window.innerHeight) * 100) / 4;

      if (percentageScroll <= 75) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    const videoContainer = document.getElementById("videoContainer");
    if (location.pathname === "/") {
      videoContainer!.style.display = "block";
    }
    return () => {
      if (videoContainer !== null) {
        videoContainer.style.display = "none";
      }
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const changeLanguage = (selectedLocale: string) => {
    console.log("cambiando idioma: " + selectedLocale);
    setLocale(selectedLocale);
  };

  return (
    <IntlProvider locale={locale} messages={message["es"]}>
      <div className="App">
        <ScrollToAnchor />
        {/* <NavBarTest idioma={locale} changeLanguage={()=>changeLanguage(locale)} /> */}
        <NavBar />

        <header className="App-header" id="home">
          <img src={imageLogo} alt="La banca gamer" />
          <p className={styles.subtitle}>
            CONVIERTE HORAS DE DIVERSIÓN EN UN CRÉDITO BANCARIO
          </p>

          <a href="#videojuegos" className={styles.buttoncalcular}>
            CALCULAR AHORA
            <span className={`material-symbols-outlined ${styles.buttonarrow}`}>
              navigate_next
            </span>
          </a>
          {showScrollDown === false ? null : (
            <div className={styles.scrolldown} style={{}}>
              <span
                className={`material-symbols-outlined ${styles.scrollicon}`}
              >
                arrow_downward
              </span>
              <p>SCROLL DOWN</p>
            </div>
          )}
        </header>
        <section id="info">
          <div className={styles.sectiontwocontainer}>
            <div className={styles.stack}>
              <h2 className={styles.stacktitle}>
                SUBE AL
                <br />
                SIGUIENTE
                <br />
                NIVEL
              </h2>
              <span className={`material-symbols-outlined ${styles.stackicon}`}>
                north_east
              </span>
              <p className={styles.pcontent}>
                Usa nuestra calculadora gamer y descubre el valor de todos los
                skins, logros y otros coleccionables asociados a tu cuenta, que
                podrás utilizar como garantía para tu próximo crédito.
                <br />
                <br />
                Un crédito accesible y sencillo para todos los que sabemos lo
                duro que es ganar un Battle Royale.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.sectiontwocontainer}>
            <h2 className={styles.sectiontwotitle}>
              VAL
              <div className={styles.iconContainer}>
                O
                {/* <div className={`material-symbols-outlined ${styles.triangle}`}>
                change_history
              </div> */}
              </div>
              RAMOS
              <br />
              TU JUEG
              <div className={styles.iconContainer}>
                O
                {/* <div className={`material-symbols-outlined ${styles.equis}`}>
                clear
              </div> */}
              </div>
            </h2>
            <div className={styles.sectiontwocontentrow}>
              <div className={styles.sectiontwocontent}>
                <p>
                  Ya sea que estés buscando financiamiento para tu próximo
                  negocio o mejorar tu set-up de juegos, estamos aquí para
                  ayudarte en ese upgrade.
                </p>
                <p>
                  Únete a nuestra comunidad gamer y haz un power up a tus
                  finanzas.
                </p>
              </div>
              <a href="#videojuegos" className={styles.buttoncalcular}>
                CALCULAR AHORA
                <span
                  className={`material-symbols-outlined ${styles.buttonarrow}`}
                >
                  navigate_next
                </span>
              </a>
            </div>
          </div>
        </section>

        <section>
          <div className={styles.sectionBackground}>
            <img src={imageSection} alt="" />
          </div>
          <div className={styles.stack}>
            <h2 className={styles.stacktitle}>
              CONOCE
              <br />
              MÁS DE
              <br />
              BANCA
              <br />
              GAMER
            </h2>
            <div className={`material-symbols-outlined ${styles.stackicontwo}`}>
              north_east
            </div>
            <Link
              to="/Faqs"
              className={`${styles.buttoncalcular} ${styles.conocemas}`}
            >
              CONOCE MÁS
              <span
                className={`material-symbols-outlined ${styles.buttonarrow}`}
              >
                navigate_next
              </span>
            </Link>
          </div>
        </section>

        <section id="videojuegos" className={styles.sectionGamesOptions}>
          <GameOptions />
        </section>
      </div>
    </IntlProvider>
  );
};

export default HomePage;
