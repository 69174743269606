import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../pages/Home/HomePage";
import { Roblox } from "../pages/roblox/CalculatorRoblox";
import { Minecraft } from "../pages/minecraft/CalculatorMinecraft";
import { CallOfDuty } from "../pages/CallOfDuty/CallOfDuty";
import { Fortnite } from "../pages/Fortnite/Fortnite";
import { Warcraft } from "../pages/Warcraft/Warcraft";
import { LeageOfLegends } from "../pages/LeageOfLegends/LeageOfLegends";
import { ClashOfClansCalculator } from "../pages/ClashOfClansPage/ClashOfClans";
import { PokemonGo } from "../pages/PokemonGo/CalculatorPokemonGo";
import { Faqs } from "../pages/faqs/Faqs";
import { Registrado } from "../pages/Registrado/Registrado";
import { ParadaisOptions } from "../pages/ParadaisOptions/ParadaisOptions";
import { GameSettings } from "../pages/ParadaisOptions/GamesSettings";
import { Valorant } from "../pages/valorant/ValorantPage";
import { EAFC24 } from "../pages/EAFC24/EAFC24";
import { RocketLeague } from "../pages/RocketLeague/RocketLeague";
import { GenshinImpact } from "../pages/GenshinImpact/GenshinImpact";
import { FreeFire } from "../pages/FreeFire/FreeFire";
const AppRouter: React.FC = () => (
    <Router>
        <Routes>
            <Route path="/" Component={HomePage} />
            <Route path="/Clash-of-Clans" Component={ClashOfClansCalculator} />
            <Route path="/Pokemon-Go" Component={PokemonGo} />
            <Route path="/Roblox" Component={Roblox} />
            <Route path="/Minecraft" Component={Minecraft} />
            <Route path="/Call-Of-Duty" Component={CallOfDuty} />
            <Route path="/Fortnite" Component={Fortnite} />
            <Route path="/Leage-of-Legends" Component={LeageOfLegends} />
            <Route path="/Warcraft" Component={Warcraft} />
            <Route path="/Valorant" Component={Valorant} />
            <Route path="/EAFC24" Component={EAFC24} />
            <Route path="/RocketLeague" Component={RocketLeague} />
            <Route path="/GenshinImpact" Component={GenshinImpact} />
            <Route path="/Free-Fire" Component={FreeFire} />
            <Route path="/Faqs" Component={Faqs} />
            <Route path="/Registrado" Component={Registrado} />
            <Route path="/Paradais" Component={ParadaisOptions} />
            <Route path="/GamesSettings" Component={GameSettings} />
        </Routes>
    </Router>);
export default AppRouter;