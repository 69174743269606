import { NavBar } from "../../components/NavBar/NavBar";
import { useEffect, useState } from "react";
import { Titular } from "../../components/Titular/Titular";
import { Row } from "../../components/Row/Row";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { Dialog } from "../../components/Dialog/Dialog";
import { callOfDuty } from "../../data/ValorDeItems";
import InputText from "../../components/InputText/InputText";
import { useLocation } from "react-router-dom";
export const CallOfDuty = () => {
  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const [level, setLevel] = useState(0);
  const [epicGuns, setEpicGuns] = useState(0);
  const [characterEpics, setCharacterEpics] = useState(0);
  const [legendaryGun, setLegendaryGun] = useState(0);
  const [legendCharacter, setLegendCharacter] = useState(0);
  const [codPoints, setCodPoints] = useState(0);
  const valorInicial = epicGuns + level > 0 ? 500 : 0;

  let valorDeCuenta =
    level * callOfDuty.level +
    epicGuns * callOfDuty.epicGuns +
    characterEpics * callOfDuty.characterEpics +
    legendaryGun * callOfDuty.legendaryGun +
    legendCharacter * callOfDuty.legendCharacter +
    codPoints * callOfDuty.codPoints +
    valorInicial;
  const nombreJuego = "Call Of Duty Mobile";

  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
      ? substracAnimation(val)
      : console.log("no cambiar");

    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };
  const location = useLocation();
  useEffect(() => {
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }
    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
      }
    };
  });
  return (
    <section className="calculadora  calculadora-padding">
      <NavBar />
      <div className="form-container">
          <div style={{ height: "100px" }}></div>
          <Titular cotizacion={cotizacion} titular={nombreJuego} />
          <span style={{ height: "60px" }}></span>
          <div className="grid">
            <InputText
              etiqueta={"Nivel"}
              valor={level}
              setValue={setLevel}
              aumento={1}
            />
            <InputText
              etiqueta={"Armas Míticas"}
              valor={epicGuns}
              setValue={setEpicGuns}
              aumento={1}
            />
            <InputText
              etiqueta={"SOLDADOS LEGENDARIOS"}
              valor={characterEpics}
              setValue={setCharacterEpics}
              aumento={1}
            />
            <InputText
              etiqueta={"ARMAS LEGENDARIAS"}
              valor={legendaryGun}
              setValue={setLegendaryGun}
              aumento={1}
            />
            <InputText
              etiqueta={"PERSONAJES LEGENDARIOS"}
              valor={legendCharacter}
              setValue={setLegendCharacter}
              aumento={1}
            />
            <InputText
              etiqueta={"COD POINTS"}
              valor={codPoints}
              setValue={setCodPoints}
              aumento={1}
            />
          </div>
        <div className="calculadora-buttons">
          <ButtonBancaGamer
            ruta=""
            action={handleClick}
            label="CALCULAR VALOR"
            tipo={2}
          />
          <ButtonBancaGamer
            ruta=""
            action={enviarCuenta}
            label="ENVIAR"
            tipo={tipoBoton}
          />
        </div>
        <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
      </div>
      <Dialog
        openDialog={openDialog}
        closeDialog={setOpendialog}
        juego={nombreJuego}
        valor={cotizacion}
      />
    </section>
  );
};
