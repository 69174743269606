import { useEffect } from "react";
import { NavBar } from "../../components/NavBar/NavBar"
import style from "./faqs.module.css"
import { useLocation } from "react-router-dom";

export const Faqs = () => {
  const location = useLocation();
    useEffect(() => {
        const videoFaq = document.getElementById("videoFaqs");
        if (location.pathname === "/Faqs") {
            videoFaq!.style.display = "block";
        }
        return () => {
          if (videoFaq !== null) {
            videoFaq.style.display = "none";
          }
        };
      });
    return <>
        
        <NavBar/>
        <section className={style.sectionfaq}>
            <div className={style.faqcontainer}>
                <h1 className={style.faqtitle}>FAQS</h1>
                <div>
                    <div>
                        <h2>¿Cómo me comunico con servicio de atención al cliente?</h2>
                        <p>Puedes comunicarte con nosotros llamando a nuestro número telefónico 3731500 opción 9</p>
                    </div>
                    <div>
                        <h2>¿El valor de mi crédito será el mismo que el de mi cuenta de videojuegos?</h2>
                        <p>No necesariamente. El valor del crédito bancario se determina mediante la evaluación de diversos factores por parte de Banco del Pacífico, como el historial crediticio, la gestión de ahorros, entre otros.</p>
                    </div>
                    <div>
                        <h2>¿PUEDO SOLICITAR ESTE CRÉDITO AL BANCO DEL PACIFICO SIN SER CLIENTE?</h2>
                        <p>No. Debido a que para solicitar el servicio de Banca Gamer necesitas ser previamente un cliente del Banco del Pacífico. Si quieres crear tu primera cuenta con nosotros, hazlo haciendo clic <a href="https://www.bancodelpacifico.com/personas/inicio#" target="_blank" rel="noreferrer">aquí.</a></p>
                    </div>
                </div>
                <div>
                    <div>
                        <h2>¿TODOS LOS CLIENTES DEL BANCO DEL PACÍFICO PUEDEN SOLICITAR EL CRÉDITO?</h2>
                        <p>Así es. El rango de tu PacifiCard, tu antigüedad como cliente del Banco del Pacífico, o incluso si has sido rechazado previamente para préstamos, no son obstáculos para acceder al beneficio de Banca Gamer.</p>
                    </div>
                    <div>
                        <h2>¿EN CUÁNTO TIEMPO TENDRÉ MI CRÉDITO?</h2>
                        <p>La verificación de banco puede tardar hasta 72 horas. Pon atención a tu correo electrónico para averiguar si tu solicitud ha sido aprobada.</p>
                    </div>
                    <div>
                        <h2>¿PUEDO SOLICITAR  VARIOS CRÉDITOS POR VARIAS CUENTAS DE VIDEOJUEGOS?</h2>
                        <p>No. El beneficio de Banca Gamer puede ser solicitado por el usuario una sola vez.</p>
                    </div>
                </div>
            </div>
        </section>
    </>
};