import { NavBar } from "../../components/NavBar/NavBar";
import { useEffect, useState } from "react";
import { Titular } from "../../components/Titular/Titular";
import { Dialog } from "../../components/Dialog/Dialog";
import { Row } from "../../components/Row/Row";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { leageOfLegends } from "../../data/ValorDeItems";
import InputText from "../../components/InputText/InputText";
import { useLocation } from "react-router-dom";
export const LeageOfLegends = () => {
  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const [server, setServer] = useState("");
  const [champions, setChampions] = useState(0);
  const [skins, setSkins] = useState(0);
  const [influencePoints, setInfluencePoints] = useState(0);
  const [riotPoints, setRiotPoints] = useState(0);
  const [runePages, setRunePages] = useState(0);
  const nombreJuego = "League of Legends";

  const handleOptionToggle = (value: string) => {
    setServer(value);
  };
  const valorInicial =
    champions + skins + influencePoints + riotPoints + runePages > 0
      ? leageOfLegends.inicial
      : 0;

  const valorDeCuenta =
    valorInicial +
    champions * leageOfLegends.champions +
    skins * leageOfLegends.skins +
    influencePoints * leageOfLegends.influencePoints +
    riotPoints * leageOfLegends.riotPoints +
    runePages * leageOfLegends.runePages;
  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
        ? substracAnimation(val)
        : console.log("no cambiar");

    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };
  const location = useLocation();
  useEffect(() => {
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }
    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
      }
    };
  });
  
  return (
    <>
      <section className="calculadora">
      <NavBar />
        <div className="form-container calculadora-padding">
          <div style={{ height: "100px" }}></div>
          <Titular cotizacion={cotizacion} titular={nombreJuego} />
          <span style={{ height: "100px" }}></span>
          <div className="grid">
            <InputText
              etiqueta={"de Campeones"}
              valor={champions}
              setValue={setChampions}
              aumento={1}
            />
            <InputText
              etiqueta={"de Skins"}
              valor={skins}
              setValue={setSkins}
              aumento={10}
            />
            <InputText
              etiqueta={"Puntos de Influencia"}
              valor={influencePoints}
              setValue={setInfluencePoints}
              aumento={1000}
            />
            <InputText
              etiqueta={"de Riot Points"}
              valor={riotPoints}
              setValue={setRiotPoints}
              aumento={100}
            />
            <InputText
              etiqueta={"Runas"}
              valor={runePages}
              setValue={setRunePages}
              aumento={1}
            />
          </div>
          <div className="calculadora-buttons">
            <ButtonBancaGamer
              ruta=""
              action={handleClick}
              label="CALCULAR VALOR"
              tipo={2}
            />
            <ButtonBancaGamer
              ruta=""
              action={enviarCuenta}
              label="ENVIAR"
              tipo={tipoBoton}
            />
          </div>
          <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
        </div>
          <Dialog
            openDialog={openDialog}
            closeDialog={setOpendialog}
            juego={nombreJuego}
            valor={cotizacion}
          />
      </section>
    </>
  );
};
